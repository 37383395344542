import classNames from "classnames";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react"
import Button from "../components/atoms/Button";
import { ArrowRightIcon } from "../components/atoms/Icons";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SEO from "../components/SEO";

import '../styles/globals.scss'
import * as styles from './styles.module.scss'


const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <main>
      <SEO
        title={t("metaTitle")}
        description={t("description")}
      />
      <div className={styles.bgElemTop}>
        <svg width="837" height="644" viewBox="0 0 837 644" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M535.225 59.1763C544.08 26.4942 578.708 0 612.569 0H788.835C822.695 0 842.966 26.4941 834.111 59.1763L733.285 431.303C724.43 463.986 689.802 490.48 655.942 490.48H479.676C445.815 490.48 425.544 463.986 434.399 431.303L535.225 59.1763Z" fill="white"/>
          <path d="M102.846 212.451C111.701 179.769 146.329 153.275 180.189 153.275H356.455C390.316 153.275 410.587 179.769 401.732 212.451L300.963 584.366C292.108 617.048 257.481 643.542 223.62 643.542H47.3539C13.4933 643.542 -6.77762 617.048 2.07744 584.366L102.846 212.451Z" fill="white"/>
        </svg>
      </div>
      <div className={styles.container}>
        <Header />
        <div className={styles.content}>
          <h1>{t("h1")}</h1>
          <div className={styles.text}>{t("earnOnSpa")}</div>
          <Button href="https://partner.glory.partners/registration" className={styles.button}>{t("startToEarn")} <ArrowRightIcon /> </Button>
        </div>
        <Footer />
      </div>
      <div className={styles.bgElemBottom}>
        <svg width="837" height="644" viewBox="0 0 837 644" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M535.225 59.1763C544.08 26.4942 578.708 0 612.569 0H788.835C822.695 0 842.966 26.4941 834.111 59.1763L733.285 431.303C724.43 463.986 689.802 490.48 655.942 490.48H479.676C445.815 490.48 425.544 463.986 434.399 431.303L535.225 59.1763Z" fill="white"/>
          <path d="M102.846 212.451C111.701 179.769 146.329 153.275 180.189 153.275H356.455C390.316 153.275 410.587 179.769 401.732 212.451L300.963 584.366C292.108 617.048 257.481 643.542 223.62 643.542H47.3539C13.4933 643.542 -6.77762 617.048 2.07744 584.366L102.846 212.451Z" fill="white"/>
        </svg>
      </div>
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;