import React from "react"

export const Bangladesh = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15"><rect width="20" height="12" fill="#006a4e"/><circle cx="9" cy="6" r="4" fill="#f42a41"/></svg>    )
}

export const India = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 225 150" width="1350" height="900"><rect width="225" height="150" fill="#f93"/><rect width="225" height="50" y="50" fill="#fff"/><rect width="225" height="50" y="100" fill="#128807"/><g transform="translate(112.5,75)"><circle r="20" fill="#008"/><circle r="17.5" fill="#fff"/><circle r="3.5" fill="#008"/><g id="d"><g id="c"><g id="b"><g id="a"><circle r="0.875" fill="#008" transform="rotate(7.5) translate(17.5)"/><path fill="#008" d="M 0,17.5 0.6,7 C 0.6,7 0,2 0,2 0,2 -0.6,7 -0.6,7 L 0,17.5 z"/></g><use xlinkHref="#a" transform="rotate(15)"/></g><use xlinkHref="#b" transform="rotate(30)"/></g><use xlinkHref="#c" transform="rotate(60)"/></g><use xlinkHref="#d" transform="rotate(120)"/><use xlinkHref="#d" transform="rotate(-120)"/></g></svg>    )
}

export const Indonesia = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 6"><rect fill="#fff" width="9" height="3"  y="2" /><rect fill="red" width="9" height="3"/></svg> 
   )
}

export const Malaysia = () => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1200" height="600" viewBox="0 0 13440 6720" fill="none">
    <g transform="scale(480)">
        <path fill="#fff" d="m0 0h28v14H0z"/>
        <path stroke="#c00" d="m1 .5h27m0 2H1m0 2h27m0 2H1"/>
        <path fill="#006" d="m0 0h14v8.5H0z"/>
        <path stroke="#c00" d="m0 8.5h28m0 2H0m0 2h28"/>
    </g>
    <path fill="#fc0" d="m4200 720 107 732 414-613-222 706 639-373-506 540 738-59-690 267 690 267-738-59 506 540-639-373 222 706-414-613-107 732-107-732-414 613 222-706-639 373 506-540-738 59 690-267-690-267 738 59-506-540 639 373-222-706 414 613zm-600 30a1280 1280 0 1 0 0 2340 1440 1440 0 1 1 0-2340z"/>
    </svg>    )
}

export const Pakistan = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="900" height="600" viewBox="-75 -40 120 80" version="1.1">
        <rect x="-75" y="-40" width="120" height="80" fill="#fff"/>
        <rect x="-45" y="-40" width="90" height="80" fill="#01411C"/>
        <circle r="24" fill="#fff"/>
        <circle r="22" cx="-7" cy="-40" fill="#01411C" transform="rotate(-41.63354, 45, -40)"/>
        <polygon points="0,-513674 301930,415571 -488533,-158734 488533,-158734 -301930,415571" fill="#fff" transform="rotate(-41.63354) translate(16) rotate(18) scale(0.00001557408)"/>
        </svg>    )
}

export const Russia = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 6" width="900" height="600"><rect fill="#fff" width="9" height="3"/><rect fill="#d52b1e" y="3" width="9" height="3"/><rect fill="#0039a6" y="2" width="9" height="2"/></svg>
    )
}

export const Uk = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="1200" height="600">
        <clipPath id="t">
            <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z"/>
        </clipPath>
        <path d="M0,0 v30 h60 v-30 z" fill="#00247d"/>
        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" stroke-width="6"/>
        <path d="M0,0 L60,30 M60,0 L0,30" clip-path="url(#t)" stroke="#cf142b" stroke-width="4"/>
        <path d="M30,0 v30 M0,15 h60" stroke="#fff" stroke-width="10"/>
        <path d="M30,0 v30 M0,15 h60" stroke="#cf142b" stroke-width="6"/>
        </svg>
    )
}

export const Uzbekistan = () => {
    return (
        <svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="55" height="39" rx="3.5" fill="white" stroke="#F5F5F5"/>
        <mask id="mask0_202_366" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="40">
        <rect x="0.5" y="0.5" width="55" height="39" rx="3.5" fill="white" stroke="white"/>
        </mask>
        <g mask="url(#mask0_202_366)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.3333H56V0H0V13.3333Z" fill="#04AAC8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40H56V26.6667H0V40Z" fill="#23C840"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3333 3.33335C11.4081 3.33335 11.4824 3.33582 11.5561 3.34069C10.9204 2.91497 10.1558 2.66669 9.33325 2.66669C7.12411 2.66669 5.33325 4.45755 5.33325 6.66669C5.33325 8.87583 7.12411 10.6667 9.33325 10.6667C10.1558 10.6667 10.9204 10.4184 11.5561 9.99269C11.4824 9.99755 11.4081 10 11.3333 10C9.4923 10 7.99992 8.50764 7.99992 6.66669C7.99992 4.82574 9.4923 3.33335 11.3333 3.33335ZM14.6666 10.6667C15.403 10.6667 15.9999 10.0697 15.9999 9.33335C15.9999 8.59697 15.403 8.00002 14.6666 8.00002C13.9302 8.00002 13.3333 8.59697 13.3333 9.33335C13.3333 10.0697 13.9302 10.6667 14.6666 10.6667ZM21.3333 4.00002C21.3333 4.7364 20.7363 5.33335 19.9999 5.33335C19.2635 5.33335 18.6666 4.7364 18.6666 4.00002C18.6666 3.26364 19.2635 2.66669 19.9999 2.66669C20.7363 2.66669 21.3333 3.26364 21.3333 4.00002ZM25.3333 5.33335C26.0696 5.33335 26.6666 4.7364 26.6666 4.00002C26.6666 3.26364 26.0696 2.66669 25.3333 2.66669C24.5969 2.66669 23.9999 3.26364 23.9999 4.00002C23.9999 4.7364 24.5969 5.33335 25.3333 5.33335ZM26.6666 9.33335C26.6666 10.0697 26.0696 10.6667 25.3333 10.6667C24.5969 10.6667 23.9999 10.0697 23.9999 9.33335C23.9999 8.59697 24.5969 8.00002 25.3333 8.00002C26.0696 8.00002 26.6666 8.59697 26.6666 9.33335ZM19.9999 10.6667C20.7363 10.6667 21.3333 10.0697 21.3333 9.33335C21.3333 8.59697 20.7363 8.00002 19.9999 8.00002C19.2635 8.00002 18.6666 8.59697 18.6666 9.33335C18.6666 10.0697 19.2635 10.6667 19.9999 10.6667Z" fill="white"/>
        </g>
        </svg>        
    )
}