import classNames from "classnames"
import * as React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { CloseIcon } from "../atoms/Icons"
import Logo from "../atoms/Logo"

import * as styles from './styles.module.scss'

interface IProps {
  open: boolean
  close: () => void
}

const MobileMenu = ({ open, close }: IProps) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.mobileMenu, { [styles.open]: open })}>
      <div className={styles.header}>
        <Logo />
        <div className={styles.controls}>
          <button className={styles.close} onClick={close}><CloseIcon /></button>
        </div>
      </div>
      <div className={styles.content}>
        <a
          href=""
          className={styles.link}
        >
          {t("signUp")}
        </a>
        <a
          href=""
          className={styles.link}
        >
          {t("signIn")}
        </a>
      </div>
    </div>
  )
}

export default MobileMenu