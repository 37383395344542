import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { InstagramIcon, TelegramIcon } from "../atoms/Icons"

import * as styles from './styles.module.scss'

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <a href={`https://www.instagram.com/${t("instagram")}`} className={styles.link} target="_blank"><InstagramIcon /> {t("instagram")}</a>
      <a href={`https://t.me/${t("telegram")}`} className={styles.link} target="_blank"><TelegramIcon /> {t("telegram")}</a>
    </footer>
  )
}

export default Footer