import * as React from 'react'
import classNames from 'classnames'
import { navigate } from "gatsby"

import * as styles from './styles.module.scss'

interface LogoProps {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  return (
    <svg
      onClick={() => navigate("/")}
      className={classNames(styles.logo, className)}
      width="260" height="44" viewBox="0 0 260 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M85.3978 40.0699C83.6436 40.0699 82.0167 40.0416 80.5171 39.985C79.0175 39.9285 76.9662 39.8011 74.3631 39.6031V10.3611H88.9629C91.0283 10.3611 92.8816 10.6582 94.5227 11.2523C96.192 11.8182 97.4936 12.6388 98.4273 13.7139C99.361 14.7891 99.8278 16.0341 99.8278 17.4488C99.8278 19.203 99.4459 20.5894 98.6819 21.608C97.918 22.5983 96.7579 23.2915 95.2017 23.6876C99.1629 24.3101 101.144 26.6444 101.144 30.6904C101.144 33.4915 99.8561 35.7551 97.2814 37.481C94.7349 39.207 90.7737 40.0699 85.3978 40.0699ZM87.1803 21.9051C88.2838 21.9051 89.1892 21.6363 89.8966 21.0987C90.6322 20.5611 91.0001 19.8679 91.0001 19.0191C91.0001 17.0102 89.7551 16.0058 87.2652 16.0058H83.8275V21.9051H87.1803ZM87.0106 33.4067C88.5668 33.4067 89.7551 33.1237 90.5756 32.5578C91.4245 31.9637 91.8489 31.1431 91.8489 30.0962C91.8489 28.9645 91.4669 28.1015 90.703 27.5073C89.939 26.8849 88.8497 26.5736 87.435 26.5736H83.785V33.2793C85.0583 33.3642 86.1335 33.4067 87.0106 33.4067Z" fill="black"/>
      <path d="M112.695 39.9002C110.319 39.9002 108.465 39.1362 107.136 37.6083C105.806 36.0805 105.141 34.0008 105.141 31.3695V18.2976H114.138V30.9875C114.138 31.6383 114.308 32.1476 114.648 32.5154C115.016 32.8549 115.482 33.0247 116.048 33.0247C116.614 33.0247 117.194 32.8549 117.788 32.5154L118.255 38.4147C117.633 38.8957 116.812 39.2635 115.794 39.5182C114.803 39.7728 113.771 39.9002 112.695 39.9002ZM109.64 15.1145C108.31 15.1145 107.178 14.7042 106.244 13.8837C105.339 13.0349 104.886 12.0021 104.886 10.7855C104.886 9.56885 105.367 8.55026 106.329 7.72973C107.291 6.9092 108.395 6.49893 109.64 6.49893C110.941 6.49893 112.059 6.92334 112.993 7.77217C113.955 8.62099 114.436 9.62543 114.436 10.7855C114.436 12.0304 113.955 13.0632 112.993 13.8837C112.031 14.7042 110.913 15.1145 109.64 15.1145Z" fill="black"/>
      <path d="M120.727 18.5947H129.257V22.9237C130.899 19.6416 133.502 18.0005 137.067 18.0005C139.783 18.0005 141.905 18.9766 143.433 20.9289C144.989 22.8529 145.767 25.5267 145.767 28.9503V39.5606H136.854V28.0591C136.854 26.8424 136.586 25.9087 136.048 25.2579C135.539 24.6072 134.817 24.2818 133.884 24.2818C133.205 24.2818 132.54 24.5082 131.889 24.9609C131.238 25.3853 130.7 26.0077 130.276 26.8283C129.88 27.6488 129.682 28.5825 129.682 29.6294V39.5606H120.727V18.5947Z" fill="black"/>
      <path d="M159.243 39.9002C157.404 39.9002 155.678 39.4192 154.065 38.4572C152.481 37.4669 151.208 36.137 150.246 34.4677C149.284 32.77 148.803 30.8885 148.803 28.823C148.803 26.7858 149.284 24.9467 150.246 23.3057C151.208 21.6363 152.495 20.3348 154.108 19.4011C155.721 18.4674 157.432 18.0005 159.243 18.0005C160.714 18.0005 162.073 18.4108 163.318 19.2313C164.591 20.0518 165.553 21.2826 166.204 22.9237V18.0005H175.116V39.5606H166.204V35.6136C164.789 38.4713 162.469 39.9002 159.243 39.9002ZM162.002 33.1945C163.134 33.1945 164.096 32.7983 164.888 32.0061C165.708 31.1856 166.147 30.2236 166.204 29.1201V28.7806C166.147 27.6488 165.694 26.6868 164.845 25.8946C164.025 25.1023 163.077 24.7062 162.002 24.7062C160.814 24.7062 159.795 25.1306 158.946 25.9794C158.126 26.8 157.715 27.7903 157.715 28.9503C157.715 30.1387 158.14 31.1431 158.989 31.9637C159.837 32.7842 160.842 33.1945 162.002 33.1945Z" fill="black"/>
      <path d="M188.71 39.9002C186.786 39.9002 185.031 39.3909 183.447 38.3723C181.891 37.3537 180.66 36.0097 179.755 34.3404C178.849 32.6427 178.396 30.846 178.396 28.9503C178.396 26.9415 178.863 25.1023 179.797 23.433C180.759 21.7353 182.032 20.4055 183.617 19.4435C185.229 18.4815 186.941 18.0005 188.752 18.0005C190.393 18.0005 191.78 18.34 192.911 19.0191C194.071 19.6981 195.005 20.7875 195.712 22.2871V11.5919L204.668 10.2762V39.5606H195.712V35.6136C194.383 38.4713 192.048 39.9002 188.71 39.9002ZM191.511 33.1945C192.699 33.1945 193.689 32.77 194.482 31.9212C195.302 31.0724 195.712 30.0821 195.712 28.9503V28.7806C195.712 27.6771 195.288 26.7292 194.439 25.937C193.59 25.1165 192.614 24.7062 191.511 24.7062C190.322 24.7062 189.318 25.1306 188.497 25.9794C187.705 26.8 187.309 27.7903 187.309 28.9503C187.309 30.1387 187.719 31.1431 188.54 31.9637C189.389 32.7842 190.379 33.1945 191.511 33.1945Z" fill="black"/>
      <path d="M218.387 39.9002C216.548 39.9002 214.822 39.4192 213.21 38.4572C211.625 37.4669 210.352 36.137 209.39 34.4677C208.428 32.77 207.947 30.8885 207.947 28.823C207.947 26.7858 208.428 24.9467 209.39 23.3057C210.352 21.6363 211.639 20.3348 213.252 19.4011C214.865 18.4674 216.577 18.0005 218.387 18.0005C219.859 18.0005 221.217 18.4108 222.462 19.2313C223.735 20.0518 224.697 21.2826 225.348 22.9237V18.0005H234.26V39.5606H225.348V35.6136C223.933 38.4713 221.613 39.9002 218.387 39.9002ZM221.146 33.1945C222.278 33.1945 223.24 32.7983 224.032 32.0061C224.853 31.1856 225.291 30.2236 225.348 29.1201V28.7806C225.291 27.6488 224.838 26.6868 223.99 25.8946C223.169 25.1023 222.221 24.7062 221.146 24.7062C219.958 24.7062 218.939 25.1306 218.09 25.9794C217.27 26.8 216.859 27.7903 216.859 28.9503C216.859 30.1387 217.284 31.1431 218.133 31.9637C218.982 32.7842 219.986 33.1945 221.146 33.1945Z" fill="black"/>
      <path d="M242.973 28.5259L236.946 18.2976H245.902L248.278 23.3057L250.74 18.2976H259.44L253.498 28.5259L259.865 39.5606H251.164L248.278 34.1282L245.477 39.5606H236.522L242.973 28.5259Z" fill="black"/>
      <path d="M37.5483 4.04598C38.1538 1.81145 40.5213 0 42.8364 0H54.888C57.2031 0 58.5891 1.81145 57.9836 4.04598L51.09 29.4889C50.4846 31.7234 48.117 33.5349 45.8019 33.5349H33.7503C31.4352 33.5349 30.0493 31.7234 30.6547 29.4889L37.5483 4.04598Z" fill="url(#paint0_linear_30_220)"/>
      <path d="M7.98594 14.5256C8.59137 12.2911 10.9589 10.4796 13.274 10.4796H25.3256C27.6407 10.4796 29.0267 12.2911 28.4212 14.5256L21.5316 39.954C20.9261 42.1886 18.5586 44 16.2435 44H4.19187C1.87678 44 0.490819 42.1886 1.09625 39.954L7.98594 14.5256Z" fill="url(#paint1_linear_30_220)"/>
      <defs>
      <linearGradient id="paint0_linear_30_220" x1="31.339" y1="-2.82374e-08" x2="29.2335" y2="33.4685" gradientUnits="userSpaceOnUse">
      <stop stop-color="#01C2FF"/>
      <stop offset="0.835602" stop-color="#1D4EFF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_30_220" x1="9.6937" y1="10.4796" x2="6.72625" y2="44.5995" gradientUnits="userSpaceOnUse">
      <stop stop-color="#01C2FF"/>
      <stop offset="0.835602" stop-color="#1D4EFF"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo