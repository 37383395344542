import classNames from "classnames"
import * as React from "react"

import * as styles from './styles.module.scss'

interface IProps {
  href: string
  type?: "primary" | "secondary"
  className?: string
  children: any
}
const Button = ({ children, href, type = "primary", className }: IProps) => {
  return (
    <a href={href} className={classNames(styles.button, styles[type], className)}>{children}</a>
  )
}

export default Button