import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useState } from "react"
import Button from "../atoms/Button"
import { BurgerIcon } from "../atoms/Icons"
import LangPicker from "../atoms/LangPicker"
import Logo from "../atoms/Logo"
import MobileMenu from "../MobileMenu"

import * as styles from './styles.module.scss'

const Header = () => {
  const { t } = useTranslation()
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  return (
    <div className={styles.header}>
      <Logo />
      <div className={styles.controls}>
        <LangPicker />
        <Button href="https://partner.glory.partners/registration">{t("signUp")}</Button>
        <Button href="https://partner.glory.partners/login" type="secondary">{t("signIn")}</Button>
        
      </div>
      <div className={styles.mobileControls}>
        <LangPicker />
        <button className={styles.burger} onClick={() => setOpenMenu(true)}><BurgerIcon /></button>
      </div>
      <MobileMenu open={openMenu} close={() => setOpenMenu(false)} />
    </div>
  )
}

export default Header