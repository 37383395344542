import React, { useMemo, useRef, useState } from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next';

import * as styles from './styles.module.scss'
import { ArrowDown } from '../../Images/Arrows';
import {
  Russia,
  Uk,
} from '../../Images/Flags'
import classNames from 'classnames';
import useOutsideAlerter from '../../../hooks/useClickOutside';

interface ILanguage {
  icon: JSX.Element
  locale: string
}

const languages: ILanguage[] = [
  {
    icon: <Russia />,
    locale: 'ru',
  },
  {
    icon: <Uk />,
    locale: 'en',
  },
]

const LangPicker = () => {
  const {language, changeLanguage} = useI18next()
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  const wrapperRef = useRef(null)
  const btnRef = useRef(null)
  useOutsideAlerter(wrapperRef, btnRef, () => setMenuIsOpen(false))

  const currentLanguage = useMemo(() => languages.find(lang => lang.locale === language), [language])

  return (
    <div className={styles.languagePicker}>
      <div className={styles.current} onClick={() => setMenuIsOpen(!menuIsOpen)} ref={btnRef}>
        <div className={styles.code}>{currentLanguage?.locale}</div>
        <div className={classNames(styles.arrowWrapper, {[styles.top]: menuIsOpen})}>
          <ArrowDown />
        </div>
      </div>
      {menuIsOpen && (
        <div className={styles.menuList} ref={wrapperRef}>
          {languages.map(lang => {
            return(
              <div onClick={() => changeLanguage(lang.locale)} className={styles.lang}>
                {lang.locale}
              </div>
            )}
          )}
        </div>
      )}
    </div>
  )
}

export default LangPicker